import React, { useState } from "react";
import "./Rating.css";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../services";
import { useTranslation } from "react-i18next";

const Rating = () => {
  const [count, setCount] = useState(false);

  const [t, i18next] = useTranslation();

  return (
    <div className="rating">
      <div className="container">
        <h2 className="rating-name">{t("rating")}</h2>
        <ScrollTrigger
          onEnter={() => setCount(true)}
          onExit={() => setCount(false)}>
          <div className="rating-title">
            <div className="rating-list">
              <h3 className="rating-number">
                {count && (
                  <CountUp start={0} duration={2.75} end={120} delay={0} />
                )}
                +
              </h3>
              <p className="rating-text">{t("rating4")}</p>
            </div>
            <div className="rating-list">
              <h3 className="rating-number">
                {count && (
                  <CountUp start={0} duration={2.75} end={43} delay={0} />
                )}
                +
              </h3>
              <p className="rating-text">{t("rating3")}</p>
            </div>
            <div className="rating-list">
              <h3 className="rating-number">
                {count && (
                  <CountUp start={0} duration={2.75} end={250} delay={0} />
                )}
                +
              </h3>
              <p className="rating-text">{t("rating2")}</p>
            </div>
            <div className="rating-list">
              <h3 className="rating-number">
                {count && (
                  <CountUp start={0} duration={2.75} end={2} delay={0} />
                )}
                +
              </h3>
              <p className="rating-text">{t("rating1")}</p>
            </div>
          </div>
        </ScrollTrigger>
      </div>
    </div>
  );
};

export default Rating;
