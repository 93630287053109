import React from "react";
import "./Section.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../services";
import { useTranslation } from "react-i18next";
import NewsImage from "../../../../assets/news.jpg";
import NewsImage2 from "../../../../assets/news2.jpg";
import NewsImage3 from "../../../../assets/news3.jpg";
import { Fade } from "react-reveal";
import Construction from "../../../../assets/construction.jpg";

const Section = () => {
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    axios
      .get(BASE_URL + "news?is_primary=true")
      .then((res) => setFilter(res.data.data[0]))
      .catch((err) => console.log(err));
  }, []);

  const [t, i18next] = useTranslation();

  return (
    <div className="section">
      <div className="container">
        <h2 className="section-name section-names">{t("news")}</h2>
        <div className="section-news">
          <Fade left>
            <div className="section-list">
              <img src={Construction} alt="" className="section-img" />
              <div className="section-item">
                <div className="section-items">
                  <button className="section-btn">
                    {!!filter && filter[`news_type_${i18next?.language}`]}
                  </button>
                </div>
                <h2 className="section-name">{t("new2")}</h2>
                <p className="section-text">{t("news1")}</p>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="section-list">
              <img src={NewsImage} alt="" className="section-img" />
              <div className="section-item">
                <div className="section-items">
                  <button className="section-btn">
                    {!!filter && filter[`news_type_${i18next?.language}`]}
                  </button>
                </div>
                <h2 className="section-name">{t("new1")}</h2>
                <p className="section-text">{t("news2")}</p>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="section-list">
              <img src={NewsImage2} alt="" className="section-img" />
              <div className="section-item">
                <div className="section-items">
                  <button className="section-btn">
                    {!!filter && filter[`news_type_${i18next?.language}`]}
                  </button>
                </div>
                <h2 className="section-name">{t("new3")}</h2>
                <p className="section-text">{t("news3")}</p>
              </div>
            </div>
            <div className="section-list">
              <img src={NewsImage3} alt="" className="section-img" />
              <div className="section-item">
                <div className="section-items">
                  <button className="section-btn">
                    {!!filter && filter[`news_type_${i18next?.language}`]}
                  </button>
                </div>
                <h2 className="section-name">{t("new4")}</h2>
                <p className="section-text">{t("news4")}</p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Section;
