import React from "react";
import { Route, Routes } from "react-router-dom";
import FilterPage from "./pages/FilterPage/FilterPage";
import HomePage from "./pages/HomePage/HomePage";
import MeetingPage from "./pages/MeetingPage/MeetingPage";
import NewsAbout from "./pages/NewsAbout/NewsAbout";
import OurProject from "./pages/Projects/Projects";

const Redirect = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:id" element={<FilterPage />} />
        <Route path="/projects" element={<OurProject />} />
        <Route path="/meeting=1" element={<MeetingPage />} />
      </Routes>
    </>
  );
};

export default Redirect;
