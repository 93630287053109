import React from "react";
import "./HomePage.css";
import Navbar from "../../components/Navbar/Navbar";
import Header from "./components/Header/Header";
import Service from "./components/Service/Service";
import Rating from "./components/Rating/Rating";
import Section from "./components/Section/Section";
import About from "./components/About/About";
import Form from "./components/Form/Form";
import Footer from "../../components/Footer/Footer";
import Projects from "./components/Projects/Projects";
import Gallery from "./components/Gallery/Gallery";
import Partner from "./components/Partner/Partner";
import BackgroundImage from "../../assets/city1.jpeg";

const HomePage = () => {
  return (
    <div className="homepage">
      <div className="homepage-list">
        <img className="homepage-video" src={BackgroundImage} alt="" />
      </div>
      <div className="homepage-items">
        <Navbar />
        <Header />
      </div>
      <Service />
      <Rating />
      <Partner />
      <Projects />
      <Section />
      <Gallery />
      <About />
      <Form />
      <Footer />
    </div>
  );
};

export default HomePage;
