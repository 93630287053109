import React from "react";
import Gallery1 from "../../../../assets/gallery1.jpg";
import Gallery2 from "../../../../assets/gallery2.jpg";
import Gallery3 from "../../../../assets/gallery3.jpg";
import Gallery4 from "../../../../assets/gallary4.jpg";
import Gallery5 from "../../../../assets/gallery5.jpg";
import Gallery6 from "../../../../assets/gallery6.jpg";
import Gallery7 from "../../../../assets/gallery7.jpg";
import Gallery8 from "../../../../assets/gallery8.jpg";
import { useTranslation } from "react-i18next";
import "./Gallery.css";

function Gallery() {
  const { t } = useTranslation();
  return (
    <div className="gallery">
      <h2
        style={{ textAlign: "center" }}
        className="section-name section-names">
        {t("gallery")}
      </h2>
      <div className="gallery-list">
        <img src={Gallery1} alt="" className="gallery-img" />
        <img src={Gallery2} alt="" className="gallery-img" />
        <img src={Gallery3} alt="" className="gallery-img" />
        <img src={Gallery4} alt="" className="gallery-img" />
        <img src={Gallery5} alt="" className="gallery-img" />
        <img src={Gallery6} alt="" className="gallery-img" />
        <img src={Gallery7} alt="" className="gallery-img" />
        <img src={Gallery8} alt="" className="gallery-img" />
      </div>
    </div>
  );
}

export default Gallery;
