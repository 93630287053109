import React from "react";
import Nav from "../../components/Nav/Nav";
import Navbar from "../../components/Navbar/Navbar";
import Meeting from "./components/Meeting/Meeting";
import "./MeetingPage.css";
import Footer from "../../components/Footer/Footer";

const MeetingPage = () => {
  return (
    <div className="meetingpage">
      <div className="meetingpage-navbar">
        <Navbar />
      </div>
      <Nav />
      <Meeting />
      <div style={{ marginTop: "40px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default MeetingPage;
