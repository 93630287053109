import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Instagram from "../../assets/icons/instagram";
import Facebook from "../../assets/icons/facebook";
import { useTranslation } from "react-i18next";
import AtaevIcon from "../../assets/ataev2.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-title">
          <div className="footer-list">
            <div className="footer-sub">
              <img src={AtaevIcon} style={{ width: 120 }} alt="" />
            </div>
            <p className="footer-texts">{t(`footer2`)}</p>
            <ul className="footer-lists">
              <li className="footer-items">
                <a
                  href="https://www.instagram.com/ataev_bahodir_build"
                  className="footer-links">
                  <Instagram />
                </a>
              </li>

              <li className="footer-items">
                <a
                  href="https://www.facebook.com/profile.php?id=61556409174910&mibextid=LQQJ4d"
                  className="footer-links">
                  <Facebook />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-list">
            <h3 className="footer-name">{t("company")}</h3>
            <ul className="footer--list">
              <li className="footer-item">
                <Link
                  onClick={() => window.scrollTo({ top: 0 })}
                  to="/"
                  className="footer-link">
                  {t(`head3`)}
                </Link>
              </li>
              <li className="footer-item">
                <Link
                  onClick={() => window.scrollTo({ top: 3600 })}
                  to="/"
                  className="footer-link">
                  {t(`head2`)}
                </Link>
              </li>
              <li className="footer-item">
                <Link
                  onClick={() => window.scrollTo({ top: 2700 })}
                  to="/"
                  className="footer-link">
                  {t(`head5`)}
                </Link>
              </li>
              <li className="footer-item">
                <Link
                  onClick={() => window.scrollTo({ top: 1500 })}
                  to="/"
                  className="footer-link">
                  {t(`projects`)}
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-list">
            <h3 className="footer-name"> {t(`head4`)}</h3>
            <ul className="footer--list">
              <li className="footer-item">
                <a href="tel:+998974839999" className="footer-link">
                  +998974839999
                </a>
              </li>
              <li className="footer-item">
                <a href="tel:+998997126666" className="footer-link">
                  +998997126666
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
