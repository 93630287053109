import React from "react";
import Projects from "../HomePage/components/Projects/Projects";
import Navbar from "../../components/Navbar/Navbar";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";

function OurProject() {
  return (
    <div>
      <div className="meetingpage-navbar">
        <Navbar />
      </div>
      <Nav />
      <div style={{ marginTop: "40px" }}>
        <Projects />
      </div>
      <div style={{ marginTop: "40px" }}>
        <Footer />
      </div>
    </div>
  );
}

export default OurProject;
