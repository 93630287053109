import React, { useState } from "react";
import "./Header.css";
import Modal from "../../../ReactModal/components/Modal/Modal";
import close from "../../../../assets/img/+.png";
import call from "../../../../assets/img/call.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import ModalSucces from "../../../ReactModal/components/ModalSucces/ModalSucces";
import { useTranslation } from "react-i18next";

const Header = () => {
  const [greatModal, setGreatModal] = useState(false);

  function openGreatModal() {
    setGreatModal(!greatModal);
  }

  const formBtn = (e) => {
    e.preventDefault();
    if (e.target[0].value.length > 0 && e.target[1].value.length > 0) {
      let botMessege = `
                     Salom, Yangi Xabar!😊%0A
                     Ismi 👤: ${e.target[0].value}%0A
                     Raqam ☎: ${e.target[1].value}%0A 
                `;

      let url = `https://api.telegram.org/bot6986401609:AAGxvxJ9iFPFAX7NTu-ZtC_lj84ipVMAqGI/sendMessage?chat_id=-1002130765028&text=${botMessege}`;
      async function fetchAsync(url) {
        let response = await fetch(url);
        let data = await response.json();
        return data;
      }
      fetchAsync(url);

      if (document.querySelector("#name").matches(".input-error")) {
        document.querySelector("#name").classList.remove("input-error");
        document.querySelector("#errorText").classList.remove("error-text1");
        document.querySelector("#closestBtn").classList.remove("close1-btn");
        document.querySelector("#closestBtn1").classList.remove("closes-btn1");
      }
      if (document.querySelector("#tel").matches(".tel-error")) {
        document.querySelector("#tel").classList.remove("tel-error");
        document.querySelector("#errorTel").classList.remove("error-tel1");
        document
          .querySelector("#closestBtn")
          .classList.remove("modal-closest-btn");
        document.querySelector("#closestBtn").classList.remove("close1-btn");
        document.querySelector("#closestBtn1").classList.remove("closes-btn1");
      }

      e.target[0].value = "";

      e.target[1].value = "";

      openGreatModal();
    } else {
      if (e.target[0].value.length < 1) {
        document.querySelector("#name").classList.add("input-error");
        document.querySelector("#errorText").classList.add("error-text1");

        document.querySelector("#closestBtn").classList.add("close1-btn");
        document.querySelector("#closestBtn1").classList.add("close2-btn");
      }
      if (e.target[1].value.length < 1) {
        document.querySelector("#tel").classList.add("tel-error");
        document.querySelector("#errorTel").classList.add("error-tel1");
        document
          .querySelector("#closestBtn")
          .classList.add("modal-closest-btn");
        document.querySelector("#closestBtn").classList.add("close1-btn");
        document.querySelector("#closestBtn1").classList.add("close2-btn");
      }
    }
  };

  const [t, i18next] = useTranslation();

  return (
    <div className="header">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        className="mySwiper">
        <SwiperSlide>
          <div className="container">
            <h2 className="header-name">ATAEV</h2>
            <h2 className="header-name">BAHODIR BUILD</h2>
            <p className="header-text">{t("headerLink")}</p>
            <div style={{ gap: 15 }} className="header-modal">
              <div className="header-title">
                <Link to="/projects" className="header-btn">
                  {t(`projects`)}
                </Link>
              </div>
              <div className="header-title">
                <a href="tel:+998974839999" className="header-btn">
                  {t(`boglanish`)}
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Header;
