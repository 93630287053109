import React from "react";

const Facebook = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4317 7.72863C15.4317 3.53282 12.0322 0.133292 7.83637 0.133292C3.64056 0.133292 0.241037 3.53282 0.241037 7.72863C0.241037 11.5263 2.99741 14.6808 6.64194 15.2321V9.93372H4.71248V7.72863H6.64194V6.0748C6.64194 4.17597 7.77512 3.10405 9.4902 3.10405C10.3477 3.10405 11.2053 3.25718 11.2053 3.25718V5.12539H10.2559C9.30644 5.12539 9.00017 5.70729 9.00017 6.31982V7.72863H11.1134L10.7765 9.93372H9.00017V15.2321C12.6447 14.6808 15.4317 11.5263 15.4317 7.72863Z"
        fill="#fff"
      />
    </svg>
  );
};

export default Facebook;
