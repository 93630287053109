import React from "react";
import "./Projects.css";
import ProjectImage from "./project.webp";
import ProjectImageOne from "./projects.webp";
import PrezidentImage from "./prezident.webp";
import SchoolImage from "./school.webp";
import ImageOne from "./image.webp";
import ImageTwo from "./image1.webp";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

function Projects() {
  const { t } = useTranslation();
  const projectData = [
    {
      id: 1,
      title: "Magic City",
      info: t("project1"),
      pPhoto: ProjectImageOne,
    },
    {
      id: 2,
      title: "Gardens Residence",
      info: t("project2"),
      pPhoto: ProjectImage,
    },
    {
      id: 3,
      title: "Fonon",
      info: t("project3"),
      pPhoto: PrezidentImage,
    },
    {
      id: 4,
      title: "Invento",
      info: t("project4"),
      pPhoto: SchoolImage,
    },
    {
      id: 5,
      title: "Presidential School",
      info: t("project5"),
      pPhoto: ImageOne,
    },
    {
      id: 6,
      title: "Boulevard",
      info: t("project6"),
      pPhoto: ImageTwo,
    },
  ];
  return (
    <>
      <div className="projectm">
        <div className="container">
          <div className="aboutm_header_box">
            <div className="aboutm_line_y"></div>
            <h2 className="aboutm_headName">{t("projects")}</h2>
          </div>
          <Fade bottom>
            <div className="projectm_flex">
              {projectData.map((data) => {
                return (
                  <div
                    key={data?.id}
                    className="projectm_cards"
                    style={{
                      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .3) 60%,rgba(0, 0, 0, 1)), url(${data?.pPhoto})`,
                    }}>
                    <div className="projectm_info_box">
                      <h3 className="projectm_cards_title">{data?.title}</h3>
                      <p className="projectm_cards_info">{data?.info}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
}

export default Projects;
