import React from "react";
import "./About.css";
import arif from "../../../../assets/img/arif.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../services";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Fade } from "react-reveal";

const About = () => {
  const [t, i18next] = useTranslation();

  const [about, setAbout] = useState([]);

  useEffect(() => {
    axios
      .get(BASE_URL + "about")
      .then((res) => setAbout(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div id="about" className="about">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper">
        <SwiperSlide>
          <div className="container">
            <p className="about-subname">{t("ATAEV BAHODIR BUILD")}</p>
            <Fade bottom>
              <h2 className="about-name">ATAEV BAHODIR BUILD</h2>
              <p className="about-text">{t("about_text")}</p>
            </Fade>
            <div className="about-item-link">
              <Link
                onClick={() => window.scrollTo({ top: 0 })}
                to={`/meeting=1`}
                className="about-btn">
                {t("batafsil")}
              </Link>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default About;
