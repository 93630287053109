import React from "react";

const Audio = () => {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7228 10.8945L2.89165 18.3395C1.80273 18.9707 0.405273 18.2065 0.405273 16.934V2.044C0.405273 0.773584 1.80071 0.00730363 2.89165 0.640493L15.7228 8.08551C15.9705 8.22691 16.1764 8.43132 16.3196 8.67798C16.4628 8.92464 16.5383 9.20479 16.5383 9.49002C16.5383 9.77524 16.4628 10.0554 16.3196 10.3021C16.1764 10.5487 15.9705 10.7531 15.7228 10.8945Z"
        fill="#fff"
      />
    </svg>
  );
};

export default Audio;
