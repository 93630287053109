import React, { useState } from "react";
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import toggle from "../../assets/icons/toggle.png";
import phone from "../../assets/icons/phone.png";
import close from "../../assets/img/+.png";
import NavModal from "../../pages/ReactModal/components/NavModal/NavModal";
import Languages from "../Language/Language";
import { useTranslation } from "react-i18next";
import Language from "./Language/Language";
import AtaevIcon from "../../assets/ataev2.png";
import AtaevIcon1 from "../../assets/ataev1.png";

const Navbar = () => {
  const [greatModal, setGreatModal] = useState(false);

  function openGreatModal() {
    setGreatModal(!greatModal);
  }
  const [navbar, setNavbar] = useState(false);
  const [logo, setLogo] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 200) {
      setNavbar(true);
      setLogo(true);
    } else {
      setNavbar(false);
      setLogo(false);
    }
  };

  const logoIcon = window.scrollY > 200 ? AtaevIcon : AtaevIcon1;
  window.addEventListener("scroll", changeBackground);

  const { t } = useTranslation();

  const location = useLocation();

  return (
    <>
      <div className={navbar ? "navbar actives" : "navbar"}>
        <div className="container">
          <ul className="navbar-list">
            <li className="navbar-item">
              <Link className={navbar ? "actives" : "navbar-links"} to="/">
                {t(`head1`)}
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                onClick={() => window.scrollTo({ top: 3100 })}
                className={navbar ? "actives" : "navbar-links"}
                to="/">
                {t(`head2`)}
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                to="/"
                onClick={() => window.scrollTo({ top: 700 })}
                className={navbar ? "actives" : "navbar-links"}>
                {t(`head3`)}
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                className={navbar ? "actives" : "navbar-links navbar-names"}
                to="/">
                <img src={logoIcon} style={{ width: 120 }} alt="" />
              </Link>
            </li>
            <li className="navbar-item">
              <Link className={navbar ? "actives" : "navbar-links"} to="/">
                {t(`head6`)}
              </Link>
            </li>
            <li className="navbar-item">
              <Link
                onClick={() => window.scrollTo({ top: 3500 })}
                className={navbar ? "actives" : "navbar-links"}
                to="/">
                {t(`head5`)}
              </Link>
            </li>
            <li className="navbar-item">
              <a
                href="#communication"
                className={navbar ? "actives" : "navbar-links"}
                to="/">
                {t(`head4`)}
              </a>
            </li>
            <li className="navbar-item">
              <Languages />
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar-mobile">
        <div className="container">
          <button onClick={openGreatModal} className="navbar-mobile-button">
            <img src={toggle} alt="" className="navbar-logo" />
          </button>
          <Link className="nav-link-name" to="/">
            <img src={logoIcon} style={{ width: 120 }} alt="" />
          </Link>
          <a href="tel:+998974839999" className="navbar-mogile-link">
            <img src={phone} alt="" className="navbar-icon" />
          </a>
        </div>
      </div>

      <NavModal show={greatModal}>
        <button onClick={() => setGreatModal()} className="navbar-close">
          <img src={close} alt="" />
        </button>
        <ul className="navbar-modal-list">
          <li onClick={() => setGreatModal()} className="navbar-modal-item">
            {location.pathname === "/" ? (
              <a
                href="#"
                onClick={() => window.scrollTo({ top: 900 })}
                className="navbar-modal-link">
                {t(`head1`)}
              </a>
            ) : (
              <Link
                onClick={() => window.scrollTo({ top: 900 })}
                to="/"
                className="navbar-modal-link">
                {t(`head1`)}
              </Link>
            )}
          </li>
          <li onClick={() => setGreatModal()} className="navbar-modal-item">
            {location.pathname === "/" ? (
              <a
                onClick={() => window.scrollTo({ top: 5800 })}
                href="#"
                className="navbar-modal-link">
                {t(`head2`)}
              </a>
            ) : (
              <Link
                onClick={() => window.scrollTo({ top: 5800 })}
                to="/"
                className="navbar-modal-link">
                {t(`head2`)}
              </Link>
            )}
          </li>
          <li onClick={() => setGreatModal()} className="navbar-modal-item">
            {location.pathname === "/" ? (
              <a
                onClick={() => window.scrollTo({ top: 900 })}
                href="#"
                className="navbar-modal-link">
                {t(`head3`)}
              </a>
            ) : (
              <Link
                onClick={() => window.scrollTo({ top: 900 })}
                to="/"
                className="navbar-modal-link">
                {t(`head3`)}
              </Link>
            )}
          </li>
          <li onClick={() => setGreatModal()} className="navbar-modal-item">
            {location.pathname === "/" ? (
              <a
                onClick={() => window.scrollTo({ top: 2700 })}
                href="#"
                className="navbar-modal-link">
                {t(`head6`)}
              </a>
            ) : (
              <Link
                onClick={() => window.scrollTo({ top: 2700 })}
                to="/"
                className="navbar-modal-link">
                {t(`head6`)}
              </Link>
            )}
          </li>
          <li onClick={() => setGreatModal()} className="navbar-modal-item">
            {location.pathname === "/" ? (
              <a
                onClick={() => window.scrollTo({ top: 6500 })}
                href="#"
                className="navbar-modal-link">
                {t(`head4`)}
              </a>
            ) : (
              <Link
                onClick={() => window.scrollTo({ top: 6500 })}
                to="/"
                className="navbar-modal-link">
                {t(`head4`)}
              </Link>
            )}
          </li>
          <li onClick={() => setGreatModal()} className="navbar-modal-item">
            {location.pathname === "/" ? (
              <a
                onClick={() => window.scrollTo({ top: 3600 })}
                href="#"
                className="navbar-modal-link">
                {t(`head5`)}
              </a>
            ) : (
              <Link
                onClick={() => window.scrollTo({ top: 3600 })}
                to="/"
                className="navbar-modal-link">
                {t(`head5`)}
              </Link>
            )}
          </li>
          <li onClick={() => setGreatModal()} className="navbar-modal-item">
            <Language />
          </li>
        </ul>
      </NavModal>
    </>
  );
};

export default Navbar;
