import React from "react";
import "./Meeting.css";
import arrow from "../../../../assets/img/row.png";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";

const Meeting = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="meeting">
        <div className="container">
          <h3 className="meeting-name">{t("bahodir")}</h3>
          <p className="meeting-title">
            <Link to="/" className="meeting-link">
              {t("home")}
            </Link>
            <img src={arrow} alt="" className="meeting-icon" />
          </p>
          <div className="meeting-page">
            <p className="meeting-text">{t("about_text")}</p>
            <div className="meeting-left">
              <img
                src="https://di.uz/img/bg/bg-company.jpg"
                alt=""
                className="meeting-icons"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Meeting;
