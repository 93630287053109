import React from "react";
import "./Partner.css";
import { useTranslation } from "react-i18next";
import House1 from "../../../../assets/house1.jpg";
import House2 from "../../../../assets/house2.jpg";
import House3 from "../../../../assets/house3.jpg";
import House4 from "../../../../assets/house4.jpg";
import House5 from "../../../../assets/house5.jpg";
import House6 from "../../../../assets/house6.jpg";


function Partner() {
  const { t } = useTranslation();
  return (
    <div>
      <h2
        style={{ textAlign: "center" }}
        className="section-name section-names">
        {t("partner")}
      </h2>
      <div className="partner-list">
        <img src={House1} alt="" className="partner-img" />
        <img src={House2} alt="" className="partner-img" />
        <img src={House3} alt="" className="partner-img" />
        <img src={House4} alt="" className="partner-img" />
        <img src={House5} alt="" className="partner-img" />
        <img src={House6} alt="" className="partner-img" />

      </div>
    </div>
  );
}

export default Partner;
