import React from "react";

const Instagram = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.22571 4.2066C5.26562 4.2066 3.70368 5.79917 3.70368 7.72863C3.70368 9.68871 5.26562 11.2507 7.22571 11.2507C9.15517 11.2507 10.7477 9.68871 10.7477 7.72863C10.7477 5.79917 9.15517 4.2066 7.22571 4.2066ZM7.22571 10.0256C5.97003 10.0256 4.92873 9.01493 4.92873 7.72863C4.92873 6.47295 5.9394 5.46228 7.22571 5.46228C8.48139 5.46228 9.49206 6.47295 9.49206 7.72863C9.49206 9.01493 8.48139 10.0256 7.22571 10.0256ZM11.6972 4.08409C11.6972 3.6247 11.3296 3.25718 10.8702 3.25718C10.4109 3.25718 10.0433 3.6247 10.0433 4.08409C10.0433 4.54349 10.4109 4.911 10.8702 4.911C11.3296 4.911 11.6972 4.54349 11.6972 4.08409ZM14.0248 4.911C13.9635 3.80845 13.7185 2.82841 12.9222 2.03213C12.1259 1.23584 11.1459 0.99083 10.0433 0.929577C8.91016 0.868325 5.51064 0.868325 4.37746 0.929577C3.27491 0.99083 2.32549 1.23584 1.49858 2.03213C0.702298 2.82841 0.457287 3.80845 0.396035 4.911C0.334782 6.04418 0.334782 9.4437 0.396035 10.5769C0.457287 11.6794 0.702298 12.6288 1.49858 13.4558C2.32549 14.252 3.27491 14.4971 4.37746 14.5583C5.51064 14.6196 8.91016 14.6196 10.0433 14.5583C11.1459 14.4971 12.1259 14.252 12.9222 13.4558C13.7185 12.6288 13.9635 11.6794 14.0248 10.5769C14.086 9.4437 14.086 6.04418 14.0248 4.911ZM12.5547 11.7713C12.3403 12.3838 11.8503 12.8432 11.2684 13.0882C10.3496 13.4558 8.20575 13.3639 7.22571 13.3639C6.21504 13.3639 4.0712 13.4558 3.18303 13.0882C2.57051 12.8432 2.11111 12.3838 1.8661 11.7713C1.49858 10.8831 1.59046 8.7393 1.59046 7.72863C1.59046 6.74858 1.49858 4.60474 1.8661 3.68595C2.11111 3.10405 2.57051 2.64465 3.18303 2.39964C4.0712 2.03213 6.21504 2.12401 7.22571 2.12401C8.20575 2.12401 10.3496 2.03213 11.2684 2.39964C11.8503 2.61403 12.3097 3.10405 12.5547 3.68595C12.9222 4.60474 12.8303 6.74858 12.8303 7.72863C12.8303 8.7393 12.9222 10.8831 12.5547 11.7713Z"
        fill="#fff"
      />
    </svg>
  );
};

export default Instagram;
