import React from "react";

const Phone = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.475 21.4693L23.4818 19.4737L20.8209 17.7001C20.3074 17.3584 19.6172 17.4704 19.2381 17.9571L17.5916 20.0737C17.2379 20.533 16.6017 20.665 16.0945 20.3842C14.9773 19.7628 13.6561 19.1776 10.7411 16.2589C7.8261 13.3401 7.23722 12.0227 6.61575 10.9055C6.33494 10.3983 6.46694 9.76207 6.92625 9.40844L9.04293 7.76193C9.52951 7.38288 9.64157 6.69276 9.2999 6.17918L7.58078 3.60024L5.53065 0.525018C5.18171 0.0015831 4.48319 -0.155564 3.94373 0.167958L1.57935 1.58639C0.940377 1.96286 0.470676 2.57077 0.267546 3.2841C-0.379024 5.64146 -0.509382 10.8417 7.82467 19.1757C16.1587 27.5098 21.3585 27.379 23.7159 26.7324C24.4292 26.5293 25.0371 26.0596 25.4136 25.4206L26.832 23.0562C27.1556 22.5167 26.9984 21.8183 26.475 21.4693Z"
        fill="#fff"
      />
    </svg>
  );
};

export default Phone;
